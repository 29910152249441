import styled from "styled-components";
import React, { useState, useRef, useEffect } from "react";
import { QueryParam, getRequest } from "../../lib/lib";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { ToggleLanguage } from "../Language/ToggleLanguage";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Terms from "../../Components/Terms";
import DotLoader from "react-spinners/DotLoader";
import "../../CSS/heLanding.css";
import lottie from "lottie-web";
import animationData from "../../Media/stars.json";

const HeLanding = () => {
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const { t } = useTranslation();
  const lang = localStorage.getItem("language");
  const op = localStorage.getItem("da_etisalat_operator");
  const msisdn = localStorage.getItem("da_etisalat_msisdn");
  const nav = useNavigate();
  const container = useRef(null);

  const confirmOtp = async (ev) => {
    ev.preventDefault();
    if (otp.length < 2) {
      toast.info("Enter OTP");
      return;
    }
    const q = QueryParam();
    const query = `?msisdn=${q.get("msisdn") || msisdn}&api_langid=${q.get(
      "langid"
    )}&api_pincode=${otp}&clickid=${q.get("clickid")}&channel=WEB&operator=${
      op || "Etisalat"
    }`;
    try {
      setLoader(true);
      const { error, data } = await getRequest({ action: "verify-otp", query });
      setLoader(false);
      // console.log(data, 'data data data');
      if (error) {
        // console.log(error)
        toast.error("Internal server Error");
        return;
      }
      if (data) {
        toast(data.description);
        if (data.result === 2) {
          Cookies.set("da-etisalat-msisdn", data.msisdn || q.get("msisdn"), {
            expires: 1,
          });
          nav("/redirect");
        }
        if (data.result === 1) {
          // setTimeout(() => {
          Cookies.set("da-etisalat-msisdn", data.msisdn || q.get("msisdn"), {
            expires: 1,
          });
          nav(
            `/welcome?msisdn=${q.get("msisdn")}&langid=${q.get(
              "langid"
            )}&uuid=${
              q.get("clickid") || localStorage.getItem("da_etisalat_uuid")
            }`
          );
          // }, 4000);
        }
      }
    } catch (ex) {
      // console.log(ex.response);
      toast.error("Network Error");
      return;
    }
  };
  useEffect(() => {
    if (container.current) {
      lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    }
  }, []);
  return (
    <React.Fragment>
      <div className="bg-black relative overflow-hidden min-h-screen">
        <div className="absolute inset-0 z-0 w-full" ref={container}></div>
        <div className="relative z-10">
          <ToggleLanguage />
          <div lang={lang} className="flex justify-around flex-wrap">
            <div className="w-full h-[250px] sm:w-[800px] sm:h-[300px] form-bg rounded mt-3 mb-3"></div>
            <div
              onSubmit={confirmOtp}
              className="wow bounceInLef p-4 rounded flex justify-center items-center flex-col"
            >
              <div className="uc-game-head-title">
                <h3 className="relative top-0 w-fit h-auto justify-center flex bg-gradient-to-r items-center from-blue-500 via-teal-500 to-pink-500 bg-clip-text text-[50px] font-extrabold text-transparent text-center select-auto">
                  {t("title")}
                </h3>
              </div>
              <label className=" text-center">{t("votp-heading")}</label>
              <br />
              <OtpInput
                inputType="number"
                value={otp}
                onChange={setOtp}
                numInputs={4}
                inputStyle={{
                  width: '40px',
                  height: "40px",
                  borderRadius: "4px",
                  margin: "-8px 2px 6px 0",
                  color: "grey"
                }}
                renderInput={(arg) => <input {...arg} />}
              />
              <div className="">
                <button
                  className="bg-blue-500 rounded p-2 text-white"
                  disabled={loader}
                  type="submit"
                >
                  {loader ? (
                    <Spinner>
                      <DotLoader size={20} color="white" />
                    </Spinner>
                  ) : (
                    t("subscribe")
                  )}
                </button>
              </div>
              <p style={{ fontSize: "11px", textAlign: "center" }}>
                {t("banner")}
              </p>
              <p
                className="text-white glow"
                style={{
                  fontSize: lang === "en" ? "11px" : "13px",
                  textAlign: "center",
                }}
              >
                {t("info")}
              </p>
              <Terms lang={lang} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeLanding;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;