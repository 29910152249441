import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo_gameit from "../Media/Documentation/assets/img/logo_gameit.png";

const Header = ({ data }) => {
  const navigate = useNavigate();
  const [searchList, setSearchList] = useState([]);
  const [activeLink, setActiveLink] = useState(""); // State to track active link

  const handleSearch = (search) => {
    let arr = [];
    data.data.forEach(e => {
      e.game.forEach(f => {
        if (search.trim() !== '' && (f.name.toLowerCase().startsWith(search.toLowerCase()))) {
          arr.push(f.name);
        }
      })
    })
    setSearchList(arr);
  }

  const handleSearchPlay = (name) => {
    data.data.forEach(e => {
      e.game.forEach(f => {
        if (f.name === name) {
          window.location.href = f.game;
        }
      })
    })
  }

  const handleNavClick = (itemName) => {
    setActiveLink(itemName); // Set active link
    // You can add navigation logic here if needed
  }

  return (
    <header>
      <div id="sticky-header" className="main-header">
        <div className="container-fluid container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="main-menu">
                <nav>
                  <div className="logo">
                    <span style={{ cursor: "pointer" }} onClick={() => {}}>
                      <img
                        src={logo_gameit}
                        alt="Logo"
                      />
                    </span>
                  </div>
                  <div id="mobile-menu" className="navbar-wrap d-none d-lg-flex">
                    <ul>
                      <li className={activeLink === "Sports" ? "show" : null}>
                        <a href="#Sports" onClick={() => handleNavClick("Sports")}>SPORTIFS</a>
                      </li>
                      <li className={activeLink === "Funny" ? "show" : null}>
                        <a href="#Funny" onClick={() => handleNavClick("Funny")}>Funny</a>
                      </li>
                      <li className={activeLink === "Action" ? "show" : null}>
                        <a href="#Action" onClick={() => handleNavClick("Action")}>D'ACTION</a>
                      </li>
                      <li className={activeLink === "Adventure" ? "show" : null}>
                        <a href="#Adventure" onClick={() => handleNavClick("Adventure")}>D'AVENTURE</a>
                      </li>
                      <li className={activeLink === "Racing" ? "show" : null}>
                        <a href="#Racing" onClick={() => handleNavClick("Racing")}>Racing</a>
                      </li>
                      <li className={activeLink === "Kids" ? "show" : null}>
                        <a href="#Kids" onClick={() => handleNavClick("Kids")}>Kids</a>
                      </li>
                      <li className={activeLink === "Arcade" ? "show" : null}>
                        <a href="#Arcade" onClick={() => handleNavClick("Arcade")}>ARCADE</a>
                      </li>
                      <li className={activeLink === "Puzzle" ? "show" : null}>
                        <a href="#Puzzle" onClick={() => handleNavClick("Puzzle")}>PUZZLE</a>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action">
                    <ul>
                      <li className="header-search">
                        <a
                          data-toggle="modal"
                          data-target="#search-modal"
                          href="/"
                        >
                          <i className="fas fa-search"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="mobile-menu"></div>
            </div>
            {/* Search Modal */}
            <div className="modal fade" id="search-modal" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input type="text" placeholder="Search here..." onChange={(e) => { handleSearch(e.target.value); }} />
                    <button>
                      <i className="fa fa-search"></i>
                    </button>
                    <div className="search-main-div">
                      {searchList.length > 0 && searchList.map((val, key) => (
                        <div key={key} className="search-div">
                          <span className="search-span" style={{ cursor: "pointer" }} onClick={() => { handleSearchPlay(val); }}>{val}</span>
                        </div>
                      ))}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
