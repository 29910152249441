import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ToggleLanguage } from "../Language/ToggleLanguage";
import { QueryParam, getRequest } from "../../lib/lib";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import DotLoader from "react-spinners/DotLoader";

const HeLanding = () => {
  const [loader, setLoader] = useState(false);
  // const [link, setLink ] = useState("/welcome");
  const lang = localStorage.getItem("language");
  const msisdn = localStorage.getItem("da_etisalat_msisdn")
  const op = localStorage.getItem("da_etisalat_operator");
  const uuid = localStorage.getItem("da_etisalat_uuid");
  // const msisdn = Cookies.get('msisdn');
  const q = QueryParam();
  const nav = useNavigate();

  const getSubStatus = async () => {
    setLoader(true);
    const query = `?msisdn=${
        q.get('msisdn') || msisdn
      }&langid=${
        q.get('langid') || lang === "en"? "1" : "0"
      }&operator=${
        op || "Zain"
      }&uuid=${
        q.get("clickid") || uuid
      }`;
    // console.log(query)
    // setLoader(true);
    const { error, data } = await getRequest({ action: 'sub-user', query: query });
    setLoader(false);
    if (error) {
      toast.error("Internal Server Error");
      return;
    }
    if (data) {
      toast.info(data.msg);
      // console.log(data, "======== SUB API DATA ========");
      if (data.result == 1) {
        Cookies.set("da-etisalat-msisdn", data.f_msisdn, { expires: 1 });
        nav('/redirect')
      } else {
        nav("/");
        return;
      };
      return;
    }
  };
  return (  
    <React.Fragment>
    <div className="area-bg-he-one">
      <ToggleLanguage />
      <Container lang={lang}>
       <Box>
       <StyledForm className="wow bounceInLeft">
          <Title className="uc-game-head-title">
            <h1>{t("title")}</h1>
          </Title>
          <Banner className="uc-game-head-title">
            {t("welcome")}
          <span style={{ fontSize: "21px"}}>{t("title")}</span>
          </Banner>
          <ButtonGroup>
            <button
            className="button"
            disabled={loader}
            onClick={getSubStatus}
            >
              { loader ? (
              <Spinner>
                <DotLoader 
                size={20} 
                color="white" />
              </Spinner>
              ) :  t("continue")}
              </button>
          </ButtonGroup>

       </StyledForm>
       </Box>
       </Container>
       </div>

    </React.Fragment>
  )
};

export default HeLanding;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Banner = styled.div`
  text-align: center;
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: max-content;
  /* background: #23242a; */
  direction: ${(props) => props.lang === "en" ? "ltr" : "rtl" };
`;

const Box = styled.div`
  position: relative;
  width: 500px;
  height: 100vh;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.4);
  backdrop-filter: blur(3px);
  margin-top: 5px;

`;

const StyledForm = styled.div`
  position: absolute;
  inset: 4px;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 25px;
  
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .button{
    width: 100%;
    outline: none;
    border: none;
    padding: 15px;
    margin: 5px;
    border-radius: 5px;
    margin-top: 25px !important;
    transition: 0.2s;
    letter-spacing: 4px;
    cursor: pointer;
    text-align: center;
    &:first-child{
      color: #255784;
      background-color: #2196f3;
      box-shadow: 0 0 10px #2196f3;
      filter: saturate(100%);
    }

  }
`;
