import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import SectionOne from "../Components/SectionOne";
import SlickSliderTop from "../Components/SlickSliderTop";
import SlickSliderBottom from "../Components/SlickSliderBottom";
import OwlSlider from "../Components/OwlSlider";
import SectionTwo from "../Components/SectionTwo";
import SectionThree from "../Components/SectionThree";
import Footer from "../Components/Footer";
import Preloader from "../Components/Preloader";
import GetRequest from "../API/GetRequest";
import { getGamesApi } from "../Data/data";
import "../CSS/new.css";
import { computeStyles } from "@popperjs/core";

const Home = () => {

  //To Call on Page Load
  useEffect(() => {
    getGamesFromBackend();

    // eslint-disable-next-line
  }, []);
  /** CHECKING IF A USER IS VALID OR NOT */


  //To Go on any other page of project
  const navigate = useNavigate();

  //Method to Call Games From Backend
  const getGamesFromBackend = () => {
    const promise = GetRequest(getGamesApi);
    promise.then(e => {
      // console.log("data ", e.response);

      if (e.message === 'Network Error') {
        // console.log("Backend is Not Working");
        navigate("/error");
      }
      processGamesData(filteredGame(e.response));
      gettingThreeGames(filteredGame(e.response));
    })
  };

  //State to Store Games Data
  const [data, setData] = useState([]);

  //Setting Data in State
  const processGamesData = (games) => {
    // console.log("games ",games);
    // var newGames = filteredGames(games);

    setData(games);
    setLoaderState(true);
    setDivState('none');
  }
  // console.log(data, "GAMES_HERE")
  let count = 0;

  //Hook to store three games array
  const [threeGamesArray, setThreeGamesArray] = useState([]);

  //Method to get Three Games randomly
  const gettingThreeGames = (games) => {
    // console.log("here is the game ", games)
    games.forEach(e => {
      let arr = e.game.slice(0, 3);
      // console.log("arr ",arr);
      setThreeGamesArray(arr);
    })
  }

  //Loader
  let Loader = require('react-loader');
  let options = {
    lines: 13,
    length: 30,
    width: 15,
    radius: 30,
    scale: 1.00,
    corners: 1,
    color: 'white',
    opacity: 0.85,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
  };

  //State for Loader
  const [loaderState, setLoaderState] = useState(false);

  //Hook to store starting div state(loading div)
  const [divState, setDivState] = useState('block');
  // console.log(data, "games");
  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      <Preloader />
      <Header data={data} />

      <main>
        <SectionOne data={data} />
        <SlickSliderTop />

        <div className="area-bg-one">

          {data.map((e, key) => {
            count = count + 1;
            // console.log(count);
            // console.log("e ",e);
            if (e.category === 'mostPlayed')
              return (<span key={key}></span>)
            else
              return (
                <div key={key}>
                  <OwlSlider
                    list={e.game}
                    data={data}
                    key={key}
                    id={e.category}
                    name={e.category}
                  />
                  {count === 2 ? <SectionTwo key={count} data={threeGamesArray} /> : <></>}
                  {count === 4 ? <SectionThree list={data} key={count} /> : <></>}
                  {count === 8 ? <SlickSliderBottom key={count} /> : <></>}
                </div>
              )
          })}
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Home;

function filteredGames(games) {
  const removeGames = games.map(prop => {
    if (prop.category === "Arcade") {
      const filteredGame = prop.game.filter(item => item.name !== "Handless Millionaire");
      return { ...prop, game: filteredGame };
    } else {
      return prop;
    }
  });

  return removeGames;
}

function filteredGame(games) {
  return games.map(item => {
    if (item.category === "Adventure") {
      item.game = item.game.filter(g => !(
        g.name.toLowerCase().startsWith("chr") ||
        g.name.toLowerCase().startsWith("chi") ||
        g.name.toLowerCase().startsWith("santa")
      ));
    }
    return item;
  })
}