import styled from "styled-components";
import React, { useRef, useState } from "react";
import { sendPost } from "../../lib/lib";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";
import { ToggleLanguage } from "../Language/ToggleLanguage";
import { useTranslation } from 'react-i18next';

import Terms from "../../Components/Terms";
import Cookies from "js-cookie";

const Login = () => {
  const [ loader, setLoader ] = useState(false);
  const { t } = useTranslation()
  // const [ operator, setOperator ] = useState(null);

  const aniRef = useRef(null);

  let lang = localStorage.getItem("language");
  const nav = useNavigate();

  const getOtp = async (e) => {
      e.preventDefault();
      
      const number =  aniRef.current.value;
      
      if(number.length <= 5 || number.length > 12) {
        toast.info("Enter A valid Number");
        return;
      }
      let msisdn = "";
      //country-code-prefiix
      if (
        number.charAt(0) === "9" &&
        number.charAt(1) === "7" &&
        number.charAt(2) === "1" 
      ) {
        msisdn = number;
      } else {
        msisdn = "971"+number;
      }
      console.log(msisdn)
    //   localStorage.setItem('da_etisalat_msisdn', msisdn);
      const subscription = {
        msisdn: msisdn
      };
      setLoader(true);
      const { error, data } = await sendPost({url: 'check-valid-sub', subscription});
      setLoader(false);
      console.log(data)
      if(error) {
        // console.log(error);
        return toast.error("Internal Server Error");
      };
      if(data) {
        let i = data.result === 0 ? "error" : "success";
      
        if(data.result === 1) {
          localStorage.setItem('da_etisalat_msisdn', msisdn);
          Cookies.set("da-etisalat-msisdn", msisdn, 
          {
            expires: 1,
            secure: false
          });
          nav(`/redirect`);
        } else {
          toast[i](data.msg);
          nav("/form");
        };
      };
  };

  
  return (  
    <React.Fragment>
    <div className="area-bg-he-one">
    <ToggleLanguage />

      <Container lang={lang}>
       <Box>
       <StyledForm 
       onSubmit={getOtp} className="wow bounceInLeft">
          <Title className="uc-game-head-title">
            <h3 className="wow backOutDown">{t('title')}</h3>
          </Title>
          <InputBox>
            <label >{t("login-title")}</label>
            <br></br>
            <StyledInput
            ref={aniRef}
            type="number"
            autoCorrect="false"
            autoFocus={false}
            placeholder=""/>
          </InputBox>
          <ButtonGroup>
            <button
            disabled={loader}
            type="submit"
            >{loader ? (
            <Spinner>
              <DotLoader 
              size={20} 
              color="white"
              />
            </Spinner>
            ) : t("login-button")}</button>

          </ButtonGroup>

       </StyledForm>
       </Box>
       </Container>
       </div>
    </React.Fragment>
  )
};

export default Login;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Banner = styled.div`
  text-align: center;
  background-image: linear-gradient(90deg,#0a0a0a 0%,#e00800 126%,#0a0a0a);
  margin-bottom: 9px;
  font-size: 12px;
  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
`;

const Container = styled.div`
  
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: max-content;
  /* background: #23242a; */
  direction: ${(props) => props.lang === "en" ? "ltr" : "rtl" };
`;

const Box = styled.div`

  position: relative;
  width: 500px;
  height: 400px;
  border-radius: 5px;
  /* background-color: rgba(0,0,0,0.4); */
  /* backdrop-filter: blur(3px); */
  /* margin-top: 5px; */

`;

const StyledForm = styled.form`
  position: absolute;
  inset: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  
`;

const StyledInput = styled.input`
  width: 100%;
  font-size: 19px;
  padding: 15px;
  border-radius: 5px;
  margin: 2px;
  border: 2px solid #55525c;
  background: #21232d;
  font-weight: bold;
  color: #fff;
  outline: none;
  transition: all 0.1s;
  &:focus{
    border: 2px solid #a527ff;
    box-shadow: 0 0 2px 2px #a527ff6a;
  }
`;
const InputBox = styled.div`
  text-align: center;
  label{ 
    color: grey;
    text-align: center !important;
  }
  input {
    outline: none;
    border: none;
    padding: 15px;
    width: 100%;
    border-radius: 4px;
    color: grey;
  }
  @media only screen and (max-width: 600px) {
    label{
      font-size: 11px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button{
    width: 100%;
    outline: none;
    border: none;
    padding: 15px;
    margin: 5px;
    border-radius: 5px;
    margin-top: 25px !important;
    transition: 0.2s;
    letter-spacing: 4px;
    cursor: pointer;
    color: #255784;
      background-color: #2196f3;
      box-shadow: 0 0 10px #2196f3;
      filter: saturate(100%);

  }
`;
