import { useEffect } from "react";
import { useState } from "react";
import i18n from "i18next";
import { styled } from "styled-components";

export const ToggleLanguage = () => {

    const language = localStorage.getItem("language");

    useEffect(() => {

        if(!language || language == "null") {
          
            localStorage.setItem("language", "en"); //default
        }
    }, [language]);

    const langChange = (lang) => {
      i18n.changeLanguage(lang);
      localStorage.setItem("language", lang);
      return;
    };

    return (
      <>
      <Switch className="switch">
      <ButtonGroup>
	    <button className={
        language === "en" ? "active" : ""
      }
      onClick={() => langChange("en")}>EN</button>

	    <button className={
        language === "ar" ? "active" : ""
      }
       onClick={() => langChange("ar")}>AR</button>
      </ButtonGroup>
  	  </Switch>
      </>
    )
};

const Switch = styled.div`
 
  position: relative;
  display: inline-block;
  margin: 0 5px;
  display: flex;
  justify-content: end;
  z-index: 999;
`;

const ButtonGroup = styled.div`
  margin : 5px ;
  background-color: #1475d7;
  border-radius: 15px;
  padding: 4px;
  z-index: 999;
  button{
    padding: 5px;
    width: 100px;
    color: white;
    font-family: 'Helvetica', Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    z-index: 999;
    cursor: pointer;
  }
  
  .active{
    background-color: whitesmoke;
    color: black;
    border-radius: 15px;
    transition: all 0.5s linear ease-in;
  }

`;
