import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../Pages/Home";
import PageNotFound from "../Pages/PageNotFound";
import SeeAll from "../Pages/SeeAll";
import ErrorPage from "../Pages/Error";
import HeHome from "../Pin-flow/LandingPage/LandingPage";
import ConfirmOtp from "../Pin-flow/ConfirmOtp/ConfirmOtp.jsx";
import Welcome from "../Pin-flow/WelcomePage/Welcome";
import OtpForm from "../Pin-flow/OtpForm/OtpForm.jsx";
import PacmanLoader from "react-spinners/PacmanLoader"
import { styled } from "styled-components";
import Cookies from "js-cookie";
import { QueryParam, getRequest } from "../lib/lib";
import Login from "../Pin-flow/Login/Login";

const Routing = () => {
  const [loader, setLoader] = useState(false);
  // const nav = useNavigate();

  // useEffect(() => {
  //   // const c_msisdn = Cookies.get("da-etisalat-msisdn");
  //   const local_msisdn = localStorage.getItem("da_etisalat_msisdn");
  //   const pathname = window.location.pathname;
  //   const cookie_msisdn = Cookies.get("da-etisalat-msisdn");
  //   /** SETTING EXT REF IN LOCALSTORAGE */
  //   let ext_ref = localStorage.getItem("da_etisalat_uuid");

  //   if(!ext_ref || (ext_ref === null || ext_ref === "null")) {
  //     localStorage.setItem(
  //       'da_etisalat_uuid', QueryParam().get('ext_ref')
  //     );
  //   } else{
  //     console.log("...")
  //   }
    
  //   if (
  //     pathname.includes("welcome") ||
  //     pathname.includes("form") ||
  //     pathname.includes("confirm")
  //   ) {
  //     console.log("/****** / / ******/");
  //     setLoader(false);
  //     return;
  //   }
  //   if (cookie_msisdn && cookie_msisdn != "null") {
  //     console.log(cookie_msisdn, " === COOKIES ===");
  //     nav("/redirect");
  //     return;
  //   }
  //   if (local_msisdn && local_msisdn != "null") {
  //     console.log("+++ M +++");
  //     // setLoader(true);
  //     checkValidUser({ msisdn: local_msisdn || cookie_msisdn })
  //     .then(res => setLoader(false))
  //     .catch(err => setLoader(false))

  //     return;
  //   }
  //   if ((!cookie_msisdn || cookie_msisdn === "null" || cookie_msisdn === null) && pathname.includes("redirect")) {
  //     console.log("+++ !M +++");
  //     nav("/");
  //     setLoader(false);
  //     // checkValidUser({ msisdn });
  //     return;
  //   } else {
  //     setLoader(false);
  //   }
  // }, []);
  // console.log("LOADER ==>", loader);

  // const checkValidUser = async ({ msisdn }) => {
  //     let query = `?msisdn=${msisdn}`;

  //     const { error, data } = await getRequest({ action: 'sub-user', query });

  //     if(error) {
  //       return "INTERNAL SERVER ERROR"
  //     }
  //     // console.log(data, "Check Sub data");
  //     if (data.result === 0) {
  //       /** Not a valid user */
  //       Cookies.remove("da-etisalat-msisdn");
  //       nav('/');
  //       return;
  //     } else {
  //       /** A valid user */
  //       Cookies.set('da-etisalat-msisdn', data.msisdn, { expires: 1 });
  //       nav('/redirect');
  //       return;
  //     }

  // };

  return (<>
    <React.Suspense fallback={
      <SuspenseSpinner>
        <PacmanLoader
          size={65}
          color="yellow" />

      </SuspenseSpinner>
    }>
      <Routes>
        <Route path="/" exact={true} element={
          loader ? (
            <SuspenseSpinner>
              <PacmanLoader
                size={65}
                color="yellow" />

            </SuspenseSpinner>
          ) : <OtpForm />
          // <Home />
        }></Route>
        <Route path="/confirm" exact={true} element={<ConfirmOtp />}></Route>
        {/* <Route path="/welcome" exact={true} element={<Welcome />}></Route> */}
        <Route path="/redirect" exact={true} element={<Home />}></Route>
        <Route path="/seeAll" exact={true} element={<SeeAll />}></Route>
        <Route path="*" exact={true} element={<PageNotFound />}></Route>
        <Route path="/error" exact={true} element={<ErrorPage />}></Route>
      </Routes>
    </React.Suspense>
  </>)
}
export default Routing;

const SuspenseSpinner = styled.div`
 background-color: #23242a;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;