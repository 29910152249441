import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { sendPost } from "../../lib/lib";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";
import { ToggleLanguage } from "../Language/ToggleLanguage";
import { useTranslation } from "react-i18next";
import Terms from "../../Components/Terms";
import Cookies from "js-cookie";
import "../../CSS/heLanding.css";
import lottie from "lottie-web";
import animationData from "../../Media/stars.json";

const OtpForm = () => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const aniRef = useRef(null);
  let lang = localStorage.getItem("language");
  const nav = useNavigate();
  const container = useRef(null);

  const getOtp = async (e) => {
    e.preventDefault();
    const number = aniRef.current.value;
    console.log(number);
    if (number.length <= 5 || number.length > 12) {
      toast.info("Enter A valid Number");
      return;
    }

    let msisdn = "";
    if (number.startsWith("971")) {
      msisdn = number;
    } else {
      msisdn = "971" + number;
    }

    localStorage.setItem("da_etisalat_msisdn", msisdn);
    localStorage.setItem("da_etisalat_operator", "Etisalat");

    const subscription = {
      msisdn,
      api_buychannel: "WEB",
      api_langid: lang === "en" ? 1 : 0,
      operator: "Etisalat",
      ext_ref: localStorage.getItem("da_etisalat_uuid"),
    };

    setLoader(true);
    const { error, data } = await sendPost({ url: "send-pin", subscription });
    setLoader(false);

    if (error) {
      toast.error("Internal Server Error");
      return;
    }

    if (data) {
      const status = data.result === 0 ? "error" : "success";
      toast[status](data.description);

      if (data.description === "Invalid CODE") {
        toast[status]("Enter a Valid Number");
        return;
      }
      if (data.result === 1) {
        localStorage.setItem("da_etisalat_uuid", data.clickid);
        nav(
          `/confirm?msisdn=${msisdn}&langid=${subscription.api_langid}&clickid=${data.clickid}`
        );
      } else if (data.result === 2) {
        Cookies.set("da-etisalat-msisdn", data.f_msisdn, { expires: 1 });
        nav("/redirect");
      }
    }
  };

  useEffect(() => {
    if (container.current) {
      lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="bg-black relative overflow-hidden min-h-screen">
        <div className="absolute inset-0 z-0 w-full" ref={container}></div>
        <div className="relative z-10">
          <ToggleLanguage />
          <div lang={lang} className="flex justify-around flex-wrap">
            <div className="w-full h-[250px] sm:w-[800px] sm:h-[300px] form-bg rounded mt-3 mb-3"></div>
            <div
              onSubmit={getOtp}
              className="wow bounceInLef p-4 rounded flex justify-center items-center flex-col"
            >
              <div className="uc-game-head-title">
                <h3 className="relative top-0 w-fit h-auto justify-center flex bg-gradient-to-r items-center from-blue-500 via-teal-500 to-pink-500 bg-clip-text text-[50px] font-extrabold text-transparent text-center select-auto">
                  {t("title")}
                </h3>
              </div>
              <label className=" text-center">{t("heading")}</label>
              <br />
              <input
                className="rounded p-2 focus:outline-blue-400 mb-2 text-gray-800 font-bold text-mono"
                ref={aniRef}
                type="number"
                autoCorrect="false"
                autoFocus={false}
                placeholder="eg (+971) 000-000-000"
              />
              <div>
                <button
                  className="bg-blue-500 rounded p-2 text-white"
                  disabled={loader}
                  type="submit"
                >
                  {loader ? (
                    <Spinner>
                      <DotLoader size={20} color="white" />
                    </Spinner>
                  ) : (
                    t("subscribe")
                  )}
                </button>
              </div>
              <p style={{ fontSize: "11px", textAlign: "center" }}>
                {t("banner")}
              </p>
              <p
              className='text-white glow'
                style={{
                  fontSize: lang === "en" ? "11px" : "13px",
                  textAlign: "center",
                  
                }}
              >
                {t("info")}
              </p>
              <Terms lang={lang} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OtpForm;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
