import React from "react";
import { sendPost } from "../lib/lib";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookies";

const Footer = () => {
  const nav = useNavigate();
    
  const unSubHandel = async () => {
    let lang = localStorage.getItem("lan");
    const msisdn = Cookies.getItem('da-etisalat-msisdn') 
    || localStorage.getItem("da_etisalat_msisdn");

    let op = localStorage.getItem("operator");
    const subscription = {
       msisdn,
      langid: lang === "ENGLISH" ? 1 : 0,
      operator: op,
    };
    console.log(subscription)
    const { error, data } = await sendPost({ url: 'unsub', subscription  });
    if(error) {
      toast.error("Internal Server Error");
      return;
    }
    console.log(data)
    if(data.result === 1) {
      toast.success(data.description);
      Cookies.removeItem('da-etisalat-msisdn');
      setTimeout(() => {
        nav('/');
      }, 4000);
    } else {
      toast.info(data.description);
    }
  };

  return (
    <footer>
      <div className="footer-top footer-bg">
        {/* <!-- newsletter-area --> */}
        {/* <div className="newsletter-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="newsletter-wrap">
                  <div className="section-title newsletter-title">
                    <h2>
                      Our <span>Newsletter</span>
                    </h2>
                  </div>
                  <div className="newsletter-form">
                    <form action="#">
                      <div className="newsletter-form-grp">
                        <i className="far fa-envelope"></i>
                        <input type="email" placeholder="Enter your email..." />
                      </div>
                      <button>
                        SUBSCRIBE <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <!-- newsletter-area-end --> */}

      <div className="copyright-wrap">
        <div className="container">
          <div className="row flex justify-evenly">
            <div className="col-lg-12 col-md-12">
              <div className="copyright-text">
                <p>
                  Copyright © {new Date().getFullYear()} <a href="/">Mecca Game Zone</a>
                </p>
                
              </div>
              
            </div>
            <p> To Deactivate, click...
            <span onClick={unSubHandel}
             className=" cursor-pointer text-white saturate-100 text-lg btn-danger p-2 rounded">
              Unsubscribe
            </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;